/* @flow */
import type { Node } from 'react';
import React, { useContext, useEffect } from 'react';
import format from 'string-template';
import swal from 'sweetalert';
import type { OnSaveResp, UpdateOptsT } from 'symptomRecordingFlow/surveyTypes';
import type { IFrameResponseT } from 'symptoTypes/surveyResponses';
import type { IFrameQuestionDataForNotifications } from 'symptoTypes/sympto-provider-creation-types';

import { ResponseDataContext } from '../responseHandlers/ResponseDataContext';
import styles from './IFrameComponent.module.scss';

type Props = {|
  questionData: IFrameQuestionDataForNotifications,
  saveData: (
    IFrameResponseT,
    questionId: string,
    UpdateOptsT
  ) => Promise<OnSaveResp>,
  inputData: IFrameResponseT,
  toggleFullScreen: (boolean) => void,
|};

const validURL = (str: string) => {
  try {
    const url = new URL(str);
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch (_) {
    return false;
  }
};

const IFrameComponent = ({
  questionData,
  saveData,
  inputData,
  toggleFullScreen,
  questionData: {
    metadata: { url, result: resultFormatting },
  },
}: Props): Node => {
  const { variableValues } = useContext(ResponseDataContext);
  const formattedURL = format(url, variableValues);
  const validatedURL = validURL(formattedURL) ? formattedURL : null;
  useEffect(() => {
    toggleFullScreen(true);
    const iframeOrigin = validatedURL ? new URL(validatedURL).origin : null;
    const iframeListener = ({
      origin,
      data,
    }: {
      origin: string,
      data: string,
    }) => {
      try {
        if (origin !== iframeOrigin) {
          // ignore messages from different origins
          return;
        }
        const {
          type,
          result,
          attributes,
        }: {
          type: 'sympto_result' | 'sympto_result_incomplete',
          result: ?string,
          attributes: ?{ [key: string]: string },
        } = JSON.parse(data);
        const shouldTriggerNextPage = type === 'sympto_result';
        if (type !== 'sympto_result' && type !== 'sympto_result_incomplete') {
          // ignore since this is not a sympto message
          return;
        }

        if (resultFormatting.type === 'json') {
          if (attributes == null) {
            throw new Error(
              `Invalid attributes type from frame: ${data}. Expected ${resultFormatting.type}`
            );
          }
          if (typeof attributes !== 'object') {
            throw new Error(
              `Invalid attributes type from frame: ${JSON.stringify(
                attributes
              )}. Expected ${resultFormatting.type}`
            );
          }
          if (
            resultFormatting.resultFields.some(
              (resultField) => attributes[resultField] == null
            )
          ) {
            throw new Error(
              `Invalid attributes from frame: ${JSON.stringify(
                attributes
              )}. Expected keys ${resultFormatting.resultFields.join(',')}`
            );
          }
        } else {
          // result type is string, do error checking here
          if (result == null) {
            throw new Error(
              `Invalid result type from frame: ${data}. Expected ${resultFormatting.type}`
            );
          }
          if (typeof result !== 'string') {
            throw new Error(
              `Invalid result type from frame: ${JSON.stringify(
                result
              )}. Expected ${resultFormatting.type}`
            );
          }
        }

        const resultData: string =
          resultFormatting.type === 'json'
            ? JSON.stringify(attributes || {})
            : result || '';
        saveData(
          {
            ...inputData,
            data: {
              response: resultData,
            },
          },
          questionData.id,
          shouldTriggerNextPage ? { triggerNextPage: true } : {}
        );
      } catch (e) {
        swal('Error', e.message, 'error');
      }
    };
    // $FlowFixMe
    window.addEventListener('message', iframeListener, false);

    return () => {
      toggleFullScreen(false);
      // $FlowFixMe
      window.removeEventListener('message', iframeListener);
    };
  }, [questionData.id, validatedURL]);

  return (
    <>
      {validatedURL && (
        <iframe
          className={styles.IFrameComponent}
          src={validatedURL}
          title={questionData.id}
        />
      )}
      {validatedURL == null && (
        <div className="display-4 text-danger p-4">Invalid URL</div>
      )}
    </>
  );
};

export default IFrameComponent;
