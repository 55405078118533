/* @flow */
import './i18n';
import 'react-toastify/dist/ReactToastify.css';

import values from 'consts';
import {
  TimelineContainer,
  TimelineViewContextProvider,
} from 'provider/alerts/TimelineViewContext';
import { DefaultStatusFilterContextProvider } from 'provider/providerPatient/DefaultStatusFilterContext';
import { PatientSocketContextProvider } from 'provider/providerPatient/PatientSocketContext';
import { PatientTableContextProvider } from 'provider/providerPatient/PatientTableContext';
import React, { lazy, Suspense, useContext } from 'react';
import { useMobileOrientation } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { compose } from 'react-recompose';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ActiveTabContextProvider } from 'utils/ActiveTabContext';
import APIAuthHOC from 'utils/APIAuthHOC';
import { ClinicDataContextProvider } from 'utils/ClinicDataContext';
import {
  DeviceTypeContextProvider,
  getDeviceType,
} from 'utils/DeviceTypeContext';
import { FloatingInstrumentContextProvider } from 'utils/FloatingInstrumentContext';
import IdleController from 'utils/IdleController';
import Loading from 'utils/loading';
import { SocketContextProvider } from 'utils/SocketProviderContext';
import {
  UserMetadataContext,
  UserMetadataContextProvider,
} from 'utils/UserMetadataContext';
import { VideoChatContextProvider } from 'utils/videoChat/VideoChatContext';

const Home = lazy(() => import('./onboarding'));
const QRCodeReader = lazy(() => import('./qrCode/QRCodeReader'));
const PageNotFound = lazy(() => import('./PageNotFound'));
const ProviderSurveyCreationPane = lazy(() =>
  import('./providerSurveyCreation/ProviderSurveyCreation')
);
const ProviderCampaignCreationPane = lazy(() =>
  import('./providerCampaignCreation/ProviderCampaignCreationFramework')
);
const ProviderDashboard = lazy(() => import('./provider/ProviderDashboard'));
const ProviderDashboardMobile = lazy(() =>
  import('./provider/ProviderDashboardMobile')
);
const ProviderPatient = lazy(() =>
  import('./provider/providerPatient/ProviderPatient')
);
const QueuedPhoneCallContainer = lazy(() =>
  import('./provider/providerPatient/phoneCall/QueuedPhoneCallContainer')
);
const PatientIntake = lazy(() => import('./patientIntake/PatientIntake'));
const ProviderInstruments = lazy(() =>
  import('./provider/providerInstruments/ProviderInstruments')
);
const SurveyCodeSingleSurveyController = lazy(() =>
  import('./surveyCode/SurveyCodeSingleSurveyController')
);
const ProviderSettings = lazy(() =>
  import('./provider/settings/ProviderSettings')
);
const ProviderListDashboard = lazy(() =>
  import('./provider/providerList/ProviderDashboard')
);
const ProviderAppointments = lazy(() =>
  import('./provider/appointments/ProviderAppointments')
);
const PatientHome = lazy(() => import('./patient/PatientHome'));
const PatientMessages = lazy(() =>
  import('./patient/patientMessages/PatientMessages')
);
const AppSurveyCodeLoading = lazy(() =>
  import('./surveyCode/AppSurveyCodeLoading')
);
const ShortLink = lazy(() => import('./ShortLink'));
const PatientLogin = lazy(() =>
  import('./patient/patientMessages/PatientLogin')
);
const AdminPane = lazy(() => import('./admin/AdminPane'));
const DocumentView = lazy(() => import('./provider/pdfView/DocumentView'));
const PatientSurveyPDF = lazy(() =>
  import('./provider/pdfView/PatientSurveyPDF')
);
const OneTimePDFDownload = lazy(() =>
  import('./provider/pdfView/OneTimePDFDownload')
);

const SurveyOnlyView = lazy(() =>
  import('./provider/surveyView/SurveyOnlyView')
);

// prefixed with /patient/
const PatientItems = (props) => (
  <UserMetadataContextProvider>
    <IdleController />
    <Routes>
      <Route path="/home" element={<PatientHome {...props} />} />
    </Routes>
  </UserMetadataContextProvider>
);

// prefixed with /admin/
const AdminItems = (props) => {
  const { data } = useContext(UserMetadataContext);
  return (
    <Routes>
      {data.role === 'admin' && (
        <>
          <Route
            path="/survey/create"
            element={<ProviderSurveyCreationPane {...props} />}
          />
          <Route path="" element={<AdminPane {...props} />} />
        </>
      )}
      {data.role !== 'admin' && (
        <Route path="*" element={<PageNotFound {...props} />} />
      )}
    </Routes>
  );
};

// prefixed with /provider/
const ProviderRoutes = ({
  deviceType,
  ...props
}: {
  deviceType: 'desktop' | 'mobile' | 'tablet',
}) => (
  <SocketContextProvider>
    <TimelineViewContextProvider>
      <DefaultStatusFilterContextProvider>
        <PatientSocketContextProvider>
          <PatientTableContextProvider>
            <ClinicDataContextProvider>
              <FloatingInstrumentContextProvider>
                <VideoChatContextProvider>
                  <Routes>
                    <Route
                      path="/patients/:patientId/intake"
                      element={<PatientIntake {...props} />}
                    />
                    <Route
                      path="/patients/:patientId"
                      element={<ProviderPatient {...props} />}
                    />
                    <Route
                      path="/dashboard"
                      element={
                        deviceType === 'mobile' ? (
                          <ProviderDashboardMobile {...props} />
                        ) : (
                          <ProviderDashboard {...props} />
                        )
                      }
                    />
                    <Route
                      path="/appointments"
                      element={<ProviderAppointments {...props} />}
                    />
                  </Routes>
                  <QueuedPhoneCallContainer />
                  <TimelineContainer />
                </VideoChatContextProvider>
              </FloatingInstrumentContextProvider>
            </ClinicDataContextProvider>
          </PatientTableContextProvider>
        </PatientSocketContextProvider>
      </DefaultStatusFilterContextProvider>
    </TimelineViewContextProvider>
  </SocketContextProvider>
);

const ProviderItems = ({
  deviceType,
  ...props
}: {
  deviceType: 'desktop' | 'mobile' | 'tablet',
}) => {
  const { data } = useContext(UserMetadataContext);
  if (data.role === 'patient' || data.role === 'admin') {
    return (
      <Routes>
        <Route path="*" element={<PageNotFound {...props} />} />
      </Routes>
    );
  }

  // all prefixed with /provider/
  return (
    <Routes>
      <Route
        path="/survey/create"
        element={<ProviderSurveyCreationPane {...props} />}
      />
      <Route
        path="/campaign/create"
        element={<ProviderCampaignCreationPane {...props} />}
      />
      <Route path="/providers" element={<ProviderListDashboard />} />
      <Route path="/settings" element={<ProviderSettings {...props} />} />
      <Route path="/media/*" element={<ProviderInstruments {...props} />} />
      <Route path="/pdf/response" element={<PatientSurveyPDF {...props} />} />
      <Route path="/pdf" element={<DocumentView {...props} />} />
      <Route
        path="*"
        element={<ProviderRoutes deviceType={deviceType} {...props} />}
      />
    </Routes>
  );
};

const App = (props) => {
  const { isLandscape } = useMobileOrientation();
  return (
    <Router>
      <DeviceTypeContextProvider isLandscape={isLandscape}>
        <ActiveTabContextProvider>
          <ToastContainer />
          <Helmet>
            <link
              id="manifest"
              rel="manifest"
              href={`${values.backend}/manifest`}
            />
          </Helmet>
          <Suspense
            fallback={
              <div className="text-center p-5">
                <Loading onlyLogo />
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<Home userStatus="login" />} />
              <Route path="/patientSurvey" element={<SurveyOnlyView />} />
              <Route path="/forgot" element={<Home userStatus="forgot" />} />
              <Route path="/reset" element={<Home userStatus="reset" />} />
              <Route
                path="/change_password"
                element={
                  <UserMetadataContextProvider>
                    <Home userStatus="change_password" />
                  </UserMetadataContextProvider>
                }
              />
              <Route path="/patient/*" element={<PatientItems {...props} />} />
              <Route
                path="/patients/:clinicShortName"
                element={<PatientLogin />}
              />
              <Route path="/messages" element={<PatientMessages />} />
              <Route
                path="/provider/*"
                element={
                  <UserMetadataContextProvider>
                    <IdleController />
                    <ProviderItems
                      deviceType={getDeviceType({
                        isLandscape,
                      })}
                    />
                  </UserMetadataContextProvider>
                }
              />
              <Route
                path="/admin"
                element={
                  <UserMetadataContextProvider>
                    <IdleController />
                    <AdminItems />
                  </UserMetadataContextProvider>
                }
              />
              <Route path="/pdf" element={<OneTimePDFDownload />} />
              <Route
                path="/surveycode/:surveyCode"
                element={<SurveyCodeSingleSurveyController />}
              />
              <Route
                path="/qrCode/:qrCodeId/:clinicId"
                element={<QRCodeReader />}
              />
              <Route path="/app/loading" element={<AppSurveyCodeLoading />} />
              <Route path="/s/:shortCode" element={<ShortLink />} />
              <Route path="/404" element={<PageNotFound />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </Suspense>
        </ActiveTabContextProvider>
      </DeviceTypeContextProvider>
    </Router>
  );
};

export default (compose(APIAuthHOC)(App): any);
