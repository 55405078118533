/* @flow */
import _ from 'lodash';
import moment from 'moment-timezone';
import { calculateConditionalValue } from 'sharedUtils/exprEval';
import { getVariableList } from 'sharedUtils/superscoreUtils';
// eslint-disable-next-line
import { extractText } from 'slate-rte/build/utils';
import stringSimilarity from 'string-similarity';
import type {
  MetrixQuestionResponseT,
  MetrixQuestionT,
  MetrixResponseT,
} from 'symptoTypes/metrix';
import type { DateInMillis } from 'symptoTypes/provider';
import type {
  GenericSavedPageDataResponseT,
  SurveyResponseT,
} from 'symptoTypes/surveyResponses';
import type {
  AnyQuestionDataT,
  DefaultVariableValueT,
  GPTExtractionVariablesT,
  GPTSuperscoreValues,
  JavaScriptVariableValueT,
  PresetVariablesT,
  QuestionId,
  QuestionIdValueVariableValueT,
  SuperscoreVariableTypesT,
  VariableMappingT,
  VariableValueT,
} from 'symptoTypes/sympto-provider-creation-types';
import { v4 as uuid } from 'uuid';

import { fillMessageWithVariables } from './fillMessage';
import { calculateSpecialVariable } from './specialSuperscoreCalc';
import { calculateAllExpressionValues } from './superscoreExpressionCalc';

export const SUPERSCORE_QUESTION_TYPES = [
  'slider',
  'iframe',
  'gpt-extractor-prompt',
  'patient-survey-selection',
  'patient-survey-multiple-selection',
  'input',
  'grader',
  'dropdown',
  'numeric_split',
  'e-sign',
  'dob_select',
  'freeform-appointment',
  'appointment',
  'care-giver',
  'multiselect',
  'geo-location',
  'gpt-prompt',
  'api-request',
  'phone-call-gather',
  'phone-call-enqueue',
  'education',
  'gpt-extractor-prompt',
  'encounter',
  'multiple-encounter',
];

type QuestionScoreDataT = {|
  id: QuestionId,
  response: ?GenericSavedPageDataResponseT,
  score: SuperscoreVariableTypesT,
|};

const formatEncounter = (
  parsedResponse: null | MetrixQuestionResponseT
): boolean | string | null => {
  if (parsedResponse == null) {
    return null;
  }
  if (parsedResponse.type === 'checkbox') {
    return parsedResponse.selected;
  }
  if (parsedResponse.type === 'text') {
    return parsedResponse.value;
  }
  if (parsedResponse.type === 'textarea') {
    return parsedResponse.value;
  }
  if (parsedResponse.type === 'formatted-numeric-input') {
    return parsedResponse.value;
  }
  if (parsedResponse.type === 'iframe-preview') {
    return parsedResponse.value;
  }
  if (parsedResponse.type === 'date-input') {
    return parsedResponse.value;
  }
  if (parsedResponse.type === 'dropdown') {
    return parsedResponse.selection ? parsedResponse.selection.title : null;
  }
  if (parsedResponse.type === 'multiselect') {
    return parsedResponse.selections.map(({ title }) => title).join(', ');
  }
  if (parsedResponse.type === 'dropdown-data-source') {
    return parsedResponse.selection ? parsedResponse.selection.title : null;
  }
  // eslint-disable-next-line no-console
  console.log((parsedResponse: empty));
  return null;
};

const calculateMetrixResponseJSON = (
  metrixResponse: MetrixResponseT,
  metrixQuestions: Array<MetrixQuestionT>
): {
  [string]: string | boolean | null,
} => {
  const score = _.fromPairs(
    _.compact(
      _.toPairs(metrixResponse).map(([key, value]) => {
        const parsedValue = formatEncounter(value);

        const metrixQuestion = metrixQuestions.find(({ id }) => id === key);
        const title = metrixQuestion
          ? extractText(metrixQuestion.attributes.title, {})
          : key;
        return [title, parsedValue];
      })
    )
  );
  return score;
};

export const calculateQuestionScores = (
  responses: SurveyResponseT,
  questions: Array<AnyQuestionDataT>,
  isFullResponse: boolean,
  patientTimezone: string
): { [key: QuestionId]: QuestionScoreDataT } =>
  _.keyBy(
    questions.map((question) => {
      const resp = responses[question.id];
      if (
        !SUPERSCORE_QUESTION_TYPES.includes(question.type) ||
        (resp != null && question.type !== resp.type)
      ) {
        return {
          id: question.id,
          score: 0,
          response: resp,
        };
      }
      // if response is null, then use default value, otherwise make sure
      // response is slider
      if (
        question.type === 'slider' &&
        (resp == null || resp.type === 'slider')
      ) {
        const {
          metadata: { description, defaultSuperScoreValue },
        } = question;
        const selectedOpt =
          resp != null && resp.type === 'slider'
            ? resp.data.value
            : // if no response, then automatically null
              null;
        const targetMc = description.find(({ value }) => value === selectedOpt);
        return {
          id: question.id,
          score:
            targetMc == null || targetMc.superScore == null
              ? defaultSuperScoreValue
              : targetMc.superScore,
          response: resp,
        };
      }
      if (
        question.type === 'education' &&
        (resp == null || resp.type === 'education')
      ) {
        return {
          id: question.id,
          score: resp != null && resp.data != null && resp.data.viewed === true,
          response: resp,
        };
      }
      if (
        question.type === 'phone-call-gather' &&
        (resp == null || resp.type === 'phone-call-gather')
      ) {
        const {
          superScore: { defaultValue, wordMatch },
          id,
        } = question;
        if (resp == null || resp.data == null || resp.data.output == null) {
          return {
            id,
            score: defaultValue,
            response: resp,
          };
        }
        const { output } = resp.data;
        const allPhrases = wordMatch.reduce(
          (acc, { phrases, mappedOutput }) =>
            phrases.reduce(
              (phraseAcc, phrase) =>
                Object.assign(phraseAcc, {
                  [phrase.trim().toLowerCase()]: mappedOutput,
                }),
              acc
            ),
          {}
        );

        const matches: {
          bestMatch: {
            target: string,
            rating: number,
          },
        } = stringSimilarity.findBestMatch(
          output.trim().toLowerCase(),
          _.keys(allPhrases)
        );
        const scoreMatch =
          matches.bestMatch.rating > 0.5
            ? allPhrases[matches.bestMatch.target]
            : output;

        return {
          id,
          score: scoreMatch,
          response: resp,
        };
      }
      if (
        question.type === 'phone-call-enqueue' &&
        (resp == null || resp.type === 'phone-call-enqueue')
      ) {
        const { id } = question;
        if (
          resp == null ||
          resp.data == null ||
          resp.data.finalStatus == null
        ) {
          return {
            id,
            score: -1,
            response: resp,
          };
        }
        const { finalStatus } = resp.data;
        const statusMapping = {
          timeout: 1,
          'patient-leave': 2,
          hangup: 3,
          success: 4,
          'no-available-providers': 5,
        };

        return {
          id,
          score: statusMapping[finalStatus],
          response: resp,
        };
      }

      const formatApptData = ({
        apptData,
      }: {
        apptData: null | {
          dateAndTime: DateInMillis,
          durationInSeconds: number,
        },
      }) => {
        if (apptData == null) {
          return 'No Appointment Scheduled';
        }
        const { dateAndTime, durationInSeconds } = apptData;
        return `${isFullResponse ? 'Scheduled - ' : 'Not Scheduled - '} ${moment
          .tz(dateAndTime, patientTimezone)
          .format('MMM Do YYYY, h:mm a z')} - ${(
          durationInSeconds / 60
        ).toFixed(1)} mins`;
      };

      if (
        question.type === 'freeform-appointment' &&
        (resp == null || resp.type === 'freeform-appointment')
      ) {
        return {
          id: question.id,
          score: formatApptData({
            apptData:
              resp != null && resp.data.appointmentSelection != null
                ? {
                    dateAndTime: resp.data.appointmentSelection.dateAndTime,
                    durationInSeconds: resp.data.appointmentSelection.duration,
                  }
                : null,
          }),
          response: resp,
        };
      }
      if (
        question.type === 'appointment' &&
        (resp == null || resp.type === 'appointment')
      ) {
        return {
          id: question.id,
          score: formatApptData({
            apptData:
              resp != null && resp.data.appointmentSelection != null
                ? {
                    dateAndTime: resp.data.appointmentSelection,
                    durationInSeconds: question.metadata.duration,
                  }
                : null,
          }),
          response: resp,
        };
      }
      if (
        question.type === 'patient-survey-selection' &&
        (resp == null || resp.type === 'patient-survey-selection')
      ) {
        const selectedOpt =
          resp != null &&
          resp.type === 'patient-survey-selection' &&
          resp.data != null;
        return {
          id: question.id,
          score: selectedOpt,
          response: resp,
        };
      }
      if (
        question.type === 'patient-survey-multiple-selection' &&
        (resp == null || resp.type === 'patient-survey-multiple-selection')
      ) {
        const selectedOpt =
          resp != null &&
          resp.type === 'patient-survey-multiple-selection' &&
          resp.data != null
            ? resp.data.value
            : null;
        const parsedData: null | Array<{ [string]: null | boolean | string }> =
          selectedOpt
            ? selectedOpt.map(
                ({
                  previewMetadataAtTimeOfSelection,
                }): { [string]: null | boolean | string } =>
                  _.toPairs(previewMetadataAtTimeOfSelection || {}).reduce(
                    (acc, [key, value]) =>
                      Object.assign(acc, {
                        [key]: value,
                      }),
                    {}
                  )
              )
            : null;
        return {
          id: question.id,
          score: parsedData,
          response: resp,
        };
      }
      if (
        question.type === 'iframe' &&
        (resp == null || resp.type === 'iframe')
      ) {
        const selectedOpt =
          resp != null && resp.type === 'iframe' && resp.data
            ? resp.data.response
            : // if no response, then automatically null
              null;
        return {
          id: question.id,
          score:
            selectedOpt == null
              ? question.superScore.defaultValue
              : selectedOpt,
          response: resp,
        };
      }
      if (
        question.type === 'gpt-prompt' &&
        (resp == null || resp.type === 'gpt-prompt')
      ) {
        return {
          id: question.id,
          score:
            resp != null && resp.data != null && resp.data.response != null
              ? resp.data.response
              : question.superScore.defaultValue,
          response: resp,
        };
      }
      if (
        question.type === 'gpt-extractor-prompt' &&
        (resp == null || resp.type === 'gpt-extractor-prompt')
      ) {
        return {
          id: question.id,
          score:
            resp != null && resp.data != null && resp.data.parsedOutput != null
              ? JSON.stringify(resp.data.parsedOutput)
              : null,
          response: resp,
        };
      }
      if (
        question.type === 'geo-location' &&
        (resp == null || resp.type === 'geo-location')
      ) {
        const selectedOpt =
          resp != null && resp.type === 'geo-location' && resp.data
            ? resp.data.response
            : // if no response, then automatically null
              null;
        const score = (() => {
          if (selectedOpt == null) {
            return question.superScore.defaultValue;
          }
          if (selectedOpt.type === 'denied') {
            return 'N/A';
          }
          return `Lat: ${selectedOpt.latitude}, Long: ${selectedOpt.longitude}, Zip: ${selectedOpt.zipCode}`;
        })();
        return {
          id: question.id,
          score,
          response: resp,
        };
      }
      if (
        question.type === 'api-request' &&
        (resp == null || resp.type === 'api-request')
      ) {
        const selectedOpt =
          resp != null && resp.type === 'api-request' && resp.data
            ? resp.data.response
            : // if no response, then automatically null
              null;
        return {
          id: question.id,
          score:
            selectedOpt == null
              ? question.superScore.defaultValue
              : _.toPairs(selectedOpt)
                  .map(([key, value]) => `${key}: ${String(value)}`)
                  .join(', '),
          response: resp,
        };
      }
      if (
        question.type === 'care-giver' &&
        (resp == null || resp.type === 'care-giver')
      ) {
        const selectedOpt =
          resp != null && resp.type === 'care-giver' && resp.data
            ? `${resp.data.patientRelationshipType} <> ${resp.data.otherPatientRelationshipType}`
            : // if no response, then automatically null
              question.superScore.defaultValue;
        return {
          id: question.id,
          score: selectedOpt,
          response: resp,
        };
      }
      if (
        question.type === 'multiselect' &&
        (resp == null || resp.type === 'multiselect')
      ) {
        const selectedOpts =
          resp != null && resp.type === 'multiselect' && resp.data
            ? resp.data.selections
            : null;
        const selectedOptIds = selectedOpts
          ? selectedOpts.map(({ value }) => value)
          : null;
        const targetMcs = question.metadata.description.filter(
          ({ value }) => selectedOptIds && selectedOptIds.includes(value)
        );
        return {
          id: question.id,
          score:
            selectedOptIds && selectedOptIds.length > 0
              ? targetMcs.map(({ superScore }) => superScore).join(',')
              : question.superScore.defaultValue,
          response: resp,
        };
      }
      if (
        question.type === 'grader' &&
        (resp == null || resp.type === 'grader')
      ) {
        const {
          superScore: { defaultValue },
        } = question;
        const selectedOpt =
          resp != null && resp.type === 'grader'
            ? resp.data.value
            : // if no response, then automatically null
              null;
        return {
          id: question.id,
          score: selectedOpt == null ? defaultValue : selectedOpt,
          response: resp,
        };
      }
      if (
        question.type === 'input' &&
        (resp == null || resp.type === 'input')
      ) {
        const {
          superScore: { defaultValue },
        } = question;
        const inputValue =
          resp != null && resp.type === 'input' ? resp.data.text : null;
        return {
          id: question.id,
          score: inputValue == null ? defaultValue : inputValue,
          response: resp,
        };
      }
      if (
        question.type === 'dropdown' &&
        (resp == null || resp.type === 'dropdown')
      ) {
        const {
          superScore: { defaultValue },
          metadata,
        } = question;
        const inputValue =
          resp != null && resp.type === 'dropdown' && resp.data.value
            ? resp.data.value.value
            : null;
        const inputScoreValue = (
          metadata.type === 'native' ? metadata.options : metadata.options
        ).find(({ value }) => value === inputValue);
        return {
          id: question.id,
          response: resp,
          score:
            inputScoreValue == null
              ? defaultValue
              : inputScoreValue.superScoreValue,
        };
      }
      if (
        question.type === 'dob_select' &&
        (resp == null || resp.type === 'dob_select')
      ) {
        const {
          superScore: { defaultValue },
        } = question;
        const inputValue =
          resp != null && resp.data.value != null && resp.type === 'dob_select'
            ? resp.data.value
            : defaultValue;
        return {
          id: question.id,
          response: resp,
          score: inputValue,
        };
      }
      if (
        question.type === 'e-sign' &&
        (resp == null || resp.type === 'e-sign')
      ) {
        return {
          id: question.id,
          response: resp,
          score:
            resp != null && resp.data != null && resp.data.type === 'picture',
        };
      }
      if (
        question.type === 'numeric_split' &&
        (resp == null || resp.type === 'numeric_split')
      ) {
        return {
          id: question.id,
          response: resp,
          score:
            resp == null || resp.data.output == null
              ? question.superScore.defaultValue
              : resp.data.output,
        };
      }
      if (
        question.type === 'encounter' &&
        (resp == null || resp.type === 'encounter')
      ) {
        const metrixResponse =
          resp != null && resp.data != null ? resp.data : {};
        const encounterData: {
          [string]: string | boolean | null,
        } = calculateMetrixResponseJSON(
          metrixResponse,
          question.metadata.metrixQuestions
        );
        return {
          id: question.id,
          response: resp,
          score: encounterData,
        };
      }
      if (
        question.type === 'multiple-encounter' &&
        (resp == null || resp.type === 'multiple-encounter')
      ) {
        const metrixResponse =
          resp != null && resp.data != null ? resp.data : { encounters: [] };
        const score: Array<{
          [key: string]: string | boolean | null,
        }> = metrixResponse.encounters.map(({ response }) =>
          calculateMetrixResponseJSON(
            response,
            question.metadata.metrixQuestions
          )
        );
        return {
          id: question.id,
          response: resp,
          score,
        };
      }
      throw new Error(
        `Invalid question / response ${JSON.stringify(question)}`
      );
    }),
    'id'
  );

const calculateScoreForQuestionIdHasValue = ({
  questionScore,
  variableValue,
  variableName,
  targetQuestion,
}: {
  questionScore: QuestionScoreDataT,
  variableValue: QuestionIdValueVariableValueT,
  variableName: string,
  targetQuestion: AnyQuestionDataT,
}): string | boolean | number | Array<null | string> | null => {
  if (
    questionScore.response != null &&
    //  not matching question type, error out
    variableValue.questionType !== targetQuestion.type
  ) {
    throw new Error(
      `Variable ${variableName}: Expected question of type ${variableValue.questionType}, but question is of type ${targetQuestion.type}`
    );
  }
  if (questionScore.response == null) {
    if (
      targetQuestion.type === 'iframe' ||
      targetQuestion.type === 'encounter' ||
      targetQuestion.type === 'patient-survey-selection' ||
      targetQuestion.type === 'gpt-extractor-prompt'
    ) {
      return null;
    }
    if (
      targetQuestion.type === 'multiselect' &&
      variableValue.targetValue === 'array-output'
    ) {
      return [];
    }
    if (targetQuestion.type === 'multiple-encounter') {
      return variableValue.targetValue === 'num-encounters' ? 0 : [];
    }
    return false;
  }
  const parsedScore = questionScore.response;
  if (
    parsedScore.type === 'multiselect' &&
    targetQuestion.type === 'multiselect'
  ) {
    if (variableValue.targetValue === 'array-output') {
      const valueMapping = _.keyBy(
        targetQuestion.metadata.description,
        'value'
      );
      return (parsedScore.data.selections || []).map(({ value }) =>
        _.get(valueMapping[value], 'superScore', '').toString()
      );
    }

    return (parsedScore.data.selections || []).some(
      ({ value }) => value === variableValue.targetValue
    );
  }
  if (parsedScore.type === 'iframe') {
    const parsedResponse =
      parsedScore.data && parsedScore.data.response
        ? JSON.parse(parsedScore.data.response)
        : null;
    return parsedResponse == null
      ? null
      : parsedResponse[variableValue.targetValue];
  }
  if (
    parsedScore.type === 'gpt-extractor-prompt' &&
    targetQuestion.type === 'gpt-extractor-prompt'
  ) {
    const parsedResponse =
      parsedScore.data != null && parsedScore.data.parsedOutput != null
        ? parsedScore.data.parsedOutput
        : null;
    const keyValue = targetQuestion.metadata.extractionAttributes.find(
      ({ id }) => id === variableValue.targetValue
    );
    return parsedResponse == null ||
      keyValue == null ||
      parsedResponse[keyValue.key] == null
      ? null
      : parsedResponse[keyValue.key];
  }
  if (parsedScore.type === 'patient-survey-selection') {
    const selection = parsedScore.data;
    if (selection == null) {
      return null;
    }
    if (variableValue.targetValue === 'Patient Survey Id') {
      return selection.patientSurveyId;
    }
    const targetItem = (selection.previewMetadataAtTimeOfSelection || {})[
      variableValue.targetValue
    ];
    return targetItem || null;
  }
  if (parsedScore.type === 'multiple-encounter') {
    if (variableValue.targetValue === 'num-encounters') {
      return parsedScore.data == null ? 0 : parsedScore.data.encounters.length;
    }
    const parsedResponse =
      parsedScore.data == null
        ? []
        : parsedScore.data.encounters.map(
            (encounter) => encounter.response[variableValue.targetValue]
          );
    const encounterResp = parsedResponse
      .map(formatEncounter)
      .map((x) => (x != null ? String(x) : x));
    return encounterResp.length === 0 ? [] : encounterResp;
  }
  if (parsedScore.type === 'encounter') {
    const parsedResponse =
      parsedScore.data != null &&
      parsedScore.data[variableValue.targetValue] != null
        ? parsedScore.data[variableValue.targetValue]
        : null;

    return formatEncounter(parsedResponse);
  }
  if (parsedScore.type === 'api-request') {
    const parsedResponse =
      parsedScore.data && parsedScore.data.response
        ? parsedScore.data.response
        : null;
    return parsedResponse == null
      ? null
      : String(parsedResponse[variableValue.targetValue]);
  }
  if (
    parsedScore.type === 'geo-location' &&
    variableValue.questionType === 'geo-location'
  ) {
    const parsedResponse: ?{
      latitude: number,
      longitude: number,
      type: 'allowed',
      zipCode: string,
    } =
      parsedScore.data &&
      parsedScore.data.response &&
      parsedScore.data.response.type === 'allowed'
        ? parsedScore.data.response
        : null;
    return parsedResponse == null
      ? null
      : String(parsedResponse[variableValue.targetValue]);
  }
  if (
    parsedScore.type === 'freeform-appointment' &&
    variableValue.questionType === 'freeform-appointment' &&
    targetQuestion.type === 'freeform-appointment'
  ) {
    const tags = _.keyBy(targetQuestion.metadata.availableTags, 'tagId');
    return (
      parsedScore.data.appointmentSelection
        ? parsedScore.data.appointmentSelection.tagIds.map((tagId) =>
            tags[tagId] ? tags[tagId].tagName : 'Invalid Tag'
          )
        : []
    ).join(',');
  }
  throw new Error(`Variable ${variableName}: Maps to an invalid question type`);
};

export const calculateVariableValues = ({
  questionScores,
  patientTvId,
  instrumentVariables,
  variableMapping,
  questions,
  gptSuperscores,
  instrumentMetadata,
  patientSurveyId,
  patientTimeZone,
  patientSurveyTags,
}: {
  questionScores: { [key: QuestionId]: QuestionScoreDataT },
  questions: Array<AnyQuestionDataT>,
  patientSurveyId: string,
  gptSuperscores: {
    configuration: GPTSuperscoreValues,
    values: { [string]: string },
  },
  patientTvId: string,
  instrumentVariables: Array<{
    variableName: string,
    id: string,
    value: string | number,
  }>,
  variableMapping: VariableMappingT,
  instrumentMetadata: {
    responseCompletion: 'Empty' | 'Partial' | 'Full',
    responseUpdatedAt: Date,
    createdAt: Date,
    isAutocompletedByInstrumentSuperscore: boolean,
    hasResponseUpdatedByDiffView: boolean,
  },
  patientTimeZone: string,
  patientSurveyTags: ?Array<string>,
}): { [string]: SuperscoreVariableTypesT } => {
  const instrumentVariableMapping = _.keyBy(instrumentVariables, 'id');
  const questionMapping = _.keyBy(questions, 'id');

  // get a list of output variables in gptSuperscores.configuration
  const gptOutputs: Array<string> = _.flatten(
    gptSuperscores.configuration.map(({ outputVariables }) => outputVariables)
  );
  // of all the values, only keep the ones in gptOutputs, or fill with empty string
  const gptValues: {
    [string]: {
      value: SuperscoreVariableTypesT,
      id: string,
    },
  } = gptOutputs.reduce((acc, output) => {
    const value = gptSuperscores.values[output];
    return Object.assign(acc, {
      [output]: {
        value: value || '',
        id: uuid(),
      },
    });
  }, {});

  const baseValues: {
    [string]: {
      value: SuperscoreVariableTypesT,
      id: string,
    },
  } = _.toPairs(variableMapping).reduce(
    (acc, [variableName, variableValue]: [string, VariableValueT]) => {
      if (variableValue.type === 'question-id') {
        const calculatedScore = questionScores[variableValue.value];
        if (calculatedScore == null) {
          throw new Error(`Variable ${variableName}: Maps to invalid question`);
        }
        return Object.assign(acc, {
          [variableName]: {
            value: calculatedScore.score,
            id: variableValue.id,
          },
        });
      }
      if (variableValue.type === 'question-id-has-value') {
        const questionScore = questionScores[variableValue.questionId];
        const targetQuestion = questionMapping[variableValue.questionId];
        if (questionScore == null) {
          throw new Error(`Variable ${variableName}: Maps to invalid question`);
        }
        return Object.assign(acc, {
          [variableName]: {
            value: calculateScoreForQuestionIdHasValue({
              questionScore,
              variableValue,
              variableName,
              targetQuestion,
            }),
            id: variableValue.id,
          },
        });
      }
      if (variableValue.type === 'question-id-numeric-split-integer-value') {
        const calculatedScore = questionScores[variableValue.questionId];
        if (calculatedScore == null) {
          throw new Error(`Variable ${variableName}: Maps to invalid question`);
        }
        if (
          calculatedScore.response != null &&
          calculatedScore.response.type !== 'numeric_split'
        ) {
          throw new Error(
            `Variable ${variableName}: Maps to a question that is not a numeric_split`
          );
        }
        const extractNumber = (input: string) => {
          // Remove non-numeric and non-decimal characters, but keep the minus sign if present
          const result = input.replace(/[^\d.-]/g, '').trim();

          // if the result starts with a minus sign, keep it
          // otheriwe minus sign not useful
          const negativeResult = result.startsWith('-')
            ? result
            : result.replace('-', '');

          if (negativeResult.trim().length === 0) {
            return negativeResult;
          }

          // Check if the result is a valid number
          if (!Number.isNaN(negativeResult)) {
            return negativeResult;
          }

          // Return null if the extracted value is not a valid number
          return null;
        };

        const number = extractNumber(String(calculatedScore.score));
        // parse out and only include digits, decimals and negative sign from score
        const isTargetValueSelected = number ? Number(number) : null;
        return Object.assign(acc, {
          [variableName]: {
            value: isTargetValueSelected,
            id: variableValue.id,
          },
        });
      }
      if (
        variableValue.type === 'expression' ||
        variableValue.type === 'javascript-variable'
      ) {
        return acc;
      }
      if (variableValue.type === 'special-variable') {
        return Object.assign(acc, {
          [variableName]: {
            value: calculateSpecialVariable({
              variableValue,
              instrumentMetadata,
              patientTimeZone,
              patientTvId,
              variableName,
              patientSurveyId,
              patientSurveyTags,
            }),
            id: variableValue.id,
          },
        });
      }
      if (variableValue.type === 'instrument-variable') {
        const instrumentVariableValue =
          instrumentVariableMapping[variableValue.value].value;
        if (instrumentVariableValue == null) {
          throw new Error(
            `Variable ${variableName}: Maps to invalid instrument variable`
          );
        }
        return Object.assign(acc, {
          [variableName]: {
            value: instrumentVariableValue,
            id: variableValue.id,
          },
        });
      }
      return acc;
    },
    gptValues
  );

  const remainingExpressions: Array<
    [string, DefaultVariableValueT | JavaScriptVariableValueT]
  > = _.compact(
    _.toPairs(variableMapping).map(([name, value]) =>
      value.type === 'expression' || value.type === 'javascript-variable'
        ? [name, value]
        : null
    )
  );

  const finalVariables = calculateAllExpressionValues({
    variablesToCalculate: remainingExpressions,
    baseMapping: baseValues,
  });

  if (
    _.keys(finalVariables).length !==
    [..._.keys(variableMapping), ...gptOutputs].length
  ) {
    // variables could be missing from either finalVariables or variableMapping
    const missingVariables = _.xor(
      _.keys(finalVariables),
      _.keys(variableMapping)
    );
    throw new Error(
      `Unable to calculate final values for all variables: ${missingVariables.join(
        ', '
      )}`
    );
  }
  return _.toPairs(finalVariables).reduce((acc, [name, { value }]) => {
    Object.assign(acc, { [name]: value });
    return acc;
  }, {});
};

const calculateSuperscoreVariableValues = ({
  responses,
  questions,
  variableMapping,
  instrumentVariables,
  hasResponseUpdatedByDiffView,
  responseCompletion,
  patientTimeZone,
  responseUpdatedAt,
  isAutocompletedByInstrumentSuperscore,
  gptSuperscores,
  patientSurveyId,
  patientTvId,
  createdAt,
  patientSurveyTags,
}: {
  responses: SurveyResponseT,
  questions: Array<AnyQuestionDataT>,
  gptSuperscores: {
    configuration: GPTSuperscoreValues,
    values: { [string]: string },
  },
  variableMapping: VariableMappingT,
  instrumentVariables: Array<{
    variableName: string,
    id: string,
    value: string | number,
  }>,

  createdAt: Date,
  // whether or not response being passed in is "full" or partial
  responseCompletion: 'Full' | 'Partial' | 'Empty',
  responseUpdatedAt: Date,
  patientTimeZone: string,
  isAutocompletedByInstrumentSuperscore: boolean,
  hasResponseUpdatedByDiffView: boolean,
  patientSurveyId: string,
  patientTvId: string,
  patientSurveyTags: ?Array<string>,
}): Array<{
  value: SuperscoreVariableTypesT,
  id: string,
  variableName: string,
}> => {
  const questionScores = calculateQuestionScores(
    responses,
    questions,
    responseCompletion === 'Full',
    patientTimeZone
  );
  const variableValues = calculateVariableValues({
    questionScores,
    variableMapping,
    instrumentVariables,
    questions,
    gptSuperscores,
    patientSurveyTags,
    patientTimeZone,
    patientTvId,
    patientSurveyId,
    instrumentMetadata: {
      responseCompletion,
      createdAt,
      responseUpdatedAt,
      isAutocompletedByInstrumentSuperscore,
      hasResponseUpdatedByDiffView,
    },
  });
  return _.toPairs(variableValues).map(([variableName, value]) => ({
    variableName,
    value,
    id:
      variableName in variableMapping
        ? variableMapping[variableName].id
        : uuid(),
  }));
};

export const RESERVED_PREFIX: string = 'reserved';

export const RESERVED_VARIABLES: Array<{
  name: $Keys<PresetVariablesT>,
  subtitle: string,
  defaultValue: *,
}> = [
  {
    name: 'reservedEncounterType',
    subtitle:
      'Set from AI phone call. Possible values: "Provider Phone Call", "Patient Phone Call", "Patient Messaging", "In Person", "Incoming Call"',
    defaultValue: '',
  },
  {
    name: 'reservedEncounterSelectedLanguage',
    subtitle:
      'Set from AI phone call (might be different from patient language). Possible values: "English", "Spanish", etc. (any language)',
    defaultValue: 'English',
  },
  {
    name: 'reservedEncounterAITranslatorEnabled',
    subtitle:
      'Set from AI phone call. Boolean (true / false). Whether or not AI translator was enabled during phone call',
    defaultValue: 'false',
  },
  {
    name: 'reservedEncounterCallEndType',
    subtitle:
      'Set from AI phone call. Possible values: "none" | "voicemail" | "failed" | "success" | "canceled" | "busy"',
    defaultValue: 'none',
  },
  {
    name: 'reservedEncounterNavigatorName',
    subtitle: 'Name of the navigator on the encounter / call',
    defaultValue: '',
  },
  {
    name: 'reservedEncounterTimeSpent',
    subtitle: 'Time spent on the encounter / call in seconds',
    defaultValue: '0',
  },
  {
    name: 'reservedMessagingCategory',
    subtitle:
      'Category of the most recent message(s) sent by the patient. Possible values: "COMPLAINT" | "DELAYED RESPONSE" | "FAILED" | "INFORMATION FOR SCHEDULING" | "INITIAL ASSISTANCE IN SCHEDULING" | "NAVIGATOR RESPONDED" | "OKAY" | "OTHER" | "SPANISH LANGUAGE" | "STOP" | "SYMPTOMS" | "TEST RESULTS" | "THANK YOU" | "UNEXPECTED" | "UNKNOWN" | "WRONG NUMBER";',
    defaultValue: 'UNKNOWN',
  },
  {
    name: 'reservedEncounterInteractionDateInISO',
    subtitle: 'Date of the interaction in ISO String format (UTC)',
    defaultValue: '',
  },
];

export const PRESET_RESERVED_VARIABLE_VALUES: PresetVariablesT = {
  reservedEncounterType: '',
  reservedEncounterSelectedLanguage: 'English',
  reservedEncounterAITranslatorEnabled: false,
  reservedEncounterCallEndType: 'none',
  reservedEncounterNavigatorName: '',
  reservedEncounterTimeSpent: 0,
  reservedMessagingCategory: 'STOP',
  reservedEncounterInteractionDateInISO: '',
};

export const coallatePassedInGPTVariables = ({
  providerMetadataVariables,
  presetVariables,
}: {
  providerMetadataVariables: { [string]: string },
  presetVariables: PresetVariablesT,
}): { [string]: SuperscoreVariableTypesT } => {
  const availableSuperscoreVariables: {
    [string]: SuperscoreVariableTypesT,
  } = {
    ...presetVariables,
  };
  const prefixedProviderMetadataVariables = _.toPairs(
    providerMetadataVariables
  ).reduce((acc, [key, value]) => {
    acc[`${RESERVED_PREFIX}${key}`] = value;
    return acc;
  }, ({}: { [name: string]: SuperscoreVariableTypesT }));
  return {
    ...availableSuperscoreVariables,
    ...prefixedProviderMetadataVariables,
  };
};

export const calculateGPTExtractionValues = ({
  gptExtractionVariables,
  ssPrefixedInstSuperscoreValues,
  providerMetadataVariables,
  presetVariables,
}: {
  gptExtractionVariables: GPTExtractionVariablesT | null,
  ssPrefixedInstSuperscoreValues: {
    [name: string]: SuperscoreVariableTypesT,
  },
  presetVariables: PresetVariablesT,
  providerMetadataVariables: { [string]: string },
}): { [var: string]: string } => {
  if (gptExtractionVariables == null) {
    return {};
  }

  // for each gpt extraction variable, calculate the value
  // conditional can include gpt value, superscore value, or instrument variable value
  // same with prompt

  // for each variable, attempt to calculate the value of each conditional
  // if the value is calculable and returns true, then return the prompt with the variables
  // filled in
  // if the value is not yet calculable (i.e. it is a variable that is not yet calculated),
  // recursively call this function until all variables are calculated

  const gptExtractionCalculations = {};
  const gptVariablesToCalculate = new Set(
    gptExtractionVariables.map(({ name }) => name)
  );
  const passedInGPTVariables = coallatePassedInGPTVariables({
    providerMetadataVariables,
    presetVariables,
  });
  const canCalculateValue = (expression: string) => {
    const availableVariables = new Set([
      ...Object.keys(ssPrefixedInstSuperscoreValues),
      ...Object.keys(gptExtractionCalculations),
      ...Object.keys(passedInGPTVariables || {}),
    ]);
    return getVariableList(expression).every((variable) =>
      availableVariables.has(variable)
    );
  };
  const availableSuperscoreVariables: {
    [string]: SuperscoreVariableTypesT,
  } = {
    ...gptExtractionCalculations,
    ...ssPrefixedInstSuperscoreValues,
    ...passedInGPTVariables,
  };

  const calculateGPTVariables = () => {
    let hasUpdated = false;
    gptExtractionVariables.forEach(({ name, expressions }) => {
      if (!gptVariablesToCalculate.has(name)) return;

      const anyMatch = expressions.some(({ conditional, prompt }) => {
        // if the conditional is not calculable, then we push this off to another iteration
        if (!canCalculateValue(conditional)) {
          return true; // this will exit the loop
        }

        const conditionalValue = calculateConditionalValue(
          conditional,
          availableSuperscoreVariables
        );
        if (conditionalValue === false) {
          return false; // continue to next conditional in expression list
        }

        // if the prompt is not calculable, then we push this off to another iteration
        if (!canCalculateValue(prompt)) {
          return true; // this will exit the loop
        }
        gptExtractionCalculations[`gpt${name}`] = fillMessageWithVariables({
          message: prompt,
          variables: availableSuperscoreVariables,
        });
        gptVariablesToCalculate.delete(name);
        hasUpdated = true;
        return true; // done calculating for this variable name, exit the loop
      });
      if (anyMatch === false) {
        gptExtractionCalculations[`gpt${name}`] = '';
        gptVariablesToCalculate.delete(name);
        hasUpdated = true;
      }
    });

    // Recursive call if updates were made and there are still variables left to calculate
    if (hasUpdated && gptVariablesToCalculate.size > 0) {
      calculateGPTVariables();
    }
    if (!hasUpdated && gptVariablesToCalculate.size > 0) {
      throw new Error(
        `Cycle Detected. Unable to calculate GPT extraction variables. Variables: ${[
          ...gptVariablesToCalculate,
        ].join(', ')}`
      );
    }
  };
  calculateGPTVariables();
  return gptExtractionCalculations;
};

export default calculateSuperscoreVariableValues;
