// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IFrameComponent_IFrameComponent__9qfPZ {\n  width: 100%;\n  height: 100%;\n  border: 0;\n  border-radius: 0;\n  overflow: hidden;\n  position: relative;\n  z-index: 1;\n}", "",{"version":3,"sources":["webpack://./src/symptomRecordingFlow/components/IFrameComponent.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,SAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;EACA,UAAA;AACF","sourcesContent":[".IFrameComponent {\n  width: 100%;\n  height: 100%;\n  border: 0;\n  border-radius: 0;\n  overflow: hidden;\n  position: relative;\n  z-index: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"IFrameComponent": "IFrameComponent_IFrameComponent__9qfPZ"
};
export default ___CSS_LOADER_EXPORT___;
